import React, { useState } from "react";
import {
  Flex,
  HStack,
  VStack,
  useBreakpointValue,
  Box,
} from "@chakra-ui/react";
import JobsList from "../components/JobsList";
import Search from "../components/Search";

const Jobs = () => {
  // Determine whether to display components horizontally or vertically based on screen size
  const isHorizontal = useBreakpointValue({ base: false, lg: true });
  const [search, setSearch] = useState({});

  return (
    <Flex alignItems={isHorizontal ? "flex-start" : "center"} justify="center">
      <Box maxW="1200px" w="100%" p={{ base: 4, lg: 8 }}>
        {isHorizontal ? (
          // Display components horizontally on larger screens
          <HStack spacing={8} justify="center" alignItems="flex-start">
            <Search search ={search} setSearch={setSearch}/>
            <JobsList search={search} setSearch={setSearch}/>
          </HStack>
        ) : (
          // Display components vertically on smaller screens
          <VStack spacing={8} align="center">
            <Search search={search} setSearch={setSearch}/>
            <JobsList search={search} setSearch={setSearch}/>
          </VStack>
        )}
      </Box>
    </Flex>
  );
};

export default Jobs;
