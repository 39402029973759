import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Button,
  Checkbox,
  CheckboxGroup,
  Stack,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Search = ({ search, setSearch }) => {
  const [startLocations, setStartLocations] = useState([]);
  const [endLocations, setEndLocations] = useState([]);
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [startLocationSubRegions, setStartLocationSubRegions] = useState([]);
  const [endLocationSubRegions, setEndLocationSubRegions] = useState([]);

  const initialRegions = {
    "Victoria": ["North VIC", "East VIC", "West VIC", "Central-Greater Melbourne VIC"],
    "New South Wales": ["South NSW", "North NSW", "West NSW", "Greater Sydney NSW"],
    "South Australia": ["North SA", "East SA", "West SA", "Greater Adelaide SA"],
    "Western Australia": ["North WA", "Central WA", "South WA", "Greater Perth WA"],
    "Northern Territory": ["North Greater Darwin NT", "Central NT", "South NT"],
    "Tasmania": [],
    "Queensland": ["North QLD", "Far North QLD", "Central QLD", "South QLD", "SE Corner Greater Brisbane QLD"],
  };

  const handleMainRegionChange = (selectedRegions, setRegions, setSubRegions) => {
    setRegions(selectedRegions);
    setSubRegions([]); // Clear subregions when main regions change
  };

  const handleSubRegionChange = (selectedSubRegions, setSubRegions) => {
    setSubRegions(selectedSubRegions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let temp = {
      pickupLocations: startLocations,
      pickupLocationSubRegions: startLocationSubRegions,
      pickupDate: dateStart,
      deliveryDate: dateEnd,
      deliveryLocations: endLocations,
      deliveryLocationSubRegions: endLocationSubRegions,
    };
    setSearch(temp);

    console.log(temp);
  };

  return (
    <Box
      bg="white"
      borderRadius="md"
      boxShadow="md"
      p="4"
      width="100%"
      maxW={{ base: "lg", lg: "sm" }}
    >
      <Heading fontWeight="bold" as="h2" size="xl" mb="4">
        Search Filters
      </Heading>
      <Box p="3">
        <form onSubmit={handleSubmit}>
          <FormLabel>
            Pickup Location:
          </FormLabel>
          <Accordion allowToggle mt="3">
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Select Pickup Region
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <CheckboxGroup
                  value={startLocations}
                  onChange={(values) => handleMainRegionChange(values, setStartLocations, setStartLocationSubRegions)}
                >
                  <Stack>
                    {Object.keys(initialRegions).map((region) => (
                      <Checkbox key={region} value={region}>
                        {region}
                      </Checkbox>
                    ))}
                  </Stack>
                </CheckboxGroup>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          {startLocations.length > 0 && (
            <>
            <FormLabel my={2}>
           Pickup Location Sub Region:
          </FormLabel>
            <Accordion allowToggle mt="3">
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Pickup Location Sub Regions
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <CheckboxGroup
                    value={startLocationSubRegions}
                    onChange={(values) => handleSubRegionChange(values, setStartLocationSubRegions)}
                  >
                    <Stack>
                      {startLocations.flatMap((region) =>
                        initialRegions[region].map((subRegion) => (
                          <Checkbox key={subRegion} value={subRegion}>
                            {subRegion}
                          </Checkbox>
                        ))
                      )}
                    </Stack>
                  </CheckboxGroup>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            </>
          )}
          <FormLabel my={2}>Drop-off Location:</FormLabel>
          <Accordion allowToggle mt="3">
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Select Drop-off Region
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <CheckboxGroup
                  value={endLocations}
                  onChange={(values) => handleMainRegionChange(values, setEndLocations, setEndLocationSubRegions)}
                >
                  <Stack>
                    {Object.keys(initialRegions).map((region) => (
                      <Checkbox key={region} value={region}>
                        {region}
                      </Checkbox>
                    ))}
                  </Stack>
                </CheckboxGroup>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          {endLocations.length > 0 && (
            <><FormLabel my={2}>Drop-off Sub Region:</FormLabel>
            <Accordion allowToggle mt="3">
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Drop-off Sub Regions
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <CheckboxGroup
                    value={endLocationSubRegions}
                    onChange={(values) => handleSubRegionChange(values, setEndLocationSubRegions)}
                  >
                    <Stack>
                      {endLocations.flatMap((region) =>
                        initialRegions[region].map((subRegion) => (
                          <Checkbox key={subRegion} value={subRegion}>
                            {subRegion}
                          </Checkbox>
                        ))
                      )}
                    </Stack>
                  </CheckboxGroup>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            </>
          )}

          <FormControl mt="3">
            <FormLabel>Pickup Date:</FormLabel>
            <DatePicker
              selected={dateStart}
              onChange={(date) => setDateStart(date)}
              placeholderText="Select Pickup Date"
              dateFormat="dd/MM/yyyy"
            />
            { dateStart && <Button size="sm" onClick={() => setDateStart("")}> Remove Date </Button>}
          </FormControl>

          <FormControl mt="3">
            <FormLabel>Delivery Date:</FormLabel>
            <DatePicker
              selected={dateEnd}
              onChange={(date) => setDateEnd(date)}
              placeholderText="Select Delivery Date"
                              dateFormat="dd/MM/yyyy"
            />
            { dateEnd && <Button size="sm" onClick={() => setDateEnd("")}> Remove Date </Button>}
          </FormControl>

          <Button type="submit" colorScheme="blue" bgColor="#000000" mt="3" marginTop="1.5rem">
            Search
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default Search;
