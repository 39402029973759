import React, { useState } from "react";
import {
  Box,
  VStack,
  FormLabel,
  Input,
  Button,
  useToast,
  InputGroup,
  Center,
} from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NavBar from "../components/NavBar";

const GetDistance = () => {
  const [deliveryAddress,setDeliveryAddress] = useState("53 civic street, diggers rest");
  const [pickupAddress,setPickupAddress] = useState("100 Pitt street");
  const toast = useToast();
  const handleSubmit = async (e) => {
    const googleMapsApiKey = "AIzaSyBtDGQnr6t1ZzQcP3CWfmi4nrC80m1Rfks";
    e.preventDefault();
    // Handle form submission logic here
    toast({
      title: "Api has been called",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    // You would send the state to your backend or state management store
    let dist = await findDistanceBetweenAddresses(pickupAddress,deliveryAddress,googleMapsApiKey)
      try {
        dist = dist["rows"][0]["elements"][0]["distance"]["text"].replace("km", "")
      }catch{
        dist = 0
      }

      console.log("Distance : ", dist)

  };
  async function findgeocodeAddress(address, googleMapsApiKey) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${googleMapsApiKey}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.status === 'OK') {
        return data.results[0].geometry.location; // Returns { lat, lng }
      } else {
        console.error('Geocoding failed:', data.error_message);
        return null;
      }
    } catch (error) {
      console.error('Geocoding request failed:', error);
      return null;
    }
  }
  

  async function getDistance(origin, destination, googleMapsApiKey) {
    const origins = `${origin.lat},${origin.lng}`;
    const destinations = `${destination.lat},${destination.lng}`;
    const baseUrl = process.env.REACT_APP_DB_URL;
  
    try {
     const response = await fetch(baseUrl + `getDistance?origins=${origins}&destinations=${destinations}&key=${googleMapsApiKey}`);
      const data = await response.json()
      return data; 
    } catch (error) {
      console.error('Error:', error);
      return null; 
    }
  }


async function findDistanceBetweenAddresses(address1, address2, googleMapsApiKey) {
  const location1 = await findgeocodeAddress(address1 + "Australia", googleMapsApiKey);
  const location2 = await findgeocodeAddress(address2 + "Australia", googleMapsApiKey);

  if (location1 && location2) {
    return await getDistance(location1, location2, googleMapsApiKey);
  } else {
    return null;
  }
}
  return (
    <>
      <Header />
      <NavBar />
      <Center minH="100vh" bg="gray.100" p={4}>
        <Box
          as="form"
          onSubmit={handleSubmit}
          p={{ base: 4, sm: 8 }} // Adjust padding as needed
          width="full"
          maxW="5xl" // Set a maximum width to make the form less wide
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="xl"
          bg="white"
          mx="auto" // Add this to center the Box horizontally if not using <Center>
        >
          <VStack onSubmit={handleSubmit} spacing={4} align="stretch">
              <FormLabel htmlFor="pickupaddress">Pickup Address</FormLabel>
                <InputGroup>
                  <Input
                    id="pickupaddress"
                    value={pickupAddress}
                    onChange={(e) => setPickupAddress(e.target.value)}
                  />
                </InputGroup>                

              <FormLabel htmlFor="deliveryaddress">Delivery Address</FormLabel>
                <InputGroup>
                  <Input
                    id="deliveryaddress"
                    value={deliveryAddress}
                    onChange={(e) => setDeliveryAddress(e.target.value)}
                  />
                </InputGroup>  
                <Button colorScheme="blue" type="submit">
                Test
                </Button> 
          </VStack>
        </Box>
      </Center>
      <Footer />
    </>
  );
};

export default GetDistance;