import React, { useState } from "react";
import Footer from '../components/Footer'
import Header from '../components/Header'
import {
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Center,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const dbUrl = process.env.REACT_APP_DB_URL;

const ChangePassword = () => {
  let navigate = useNavigate();
  const { token } = useParams();
    const toast = useToast();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleClick = async () => {
      if (password !== confirmPassword) {
        showToast("Passwords do not match.", "error");
        return;
      }
  
      try {
        const response = await fetch(dbUrl + `api/auth/resetpassword/${token}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ newPassword: password }),
        });
  
        if (response.ok) {
          const data = await response.json();
          showToast(data.message || "Password has been reset successfully.");
          navigate('/login');
        } else {
          const errorData = await response.json();
          showToast(errorData.error || "Error resetting password.", "error");
        }
      } catch (error) {
        showToast("Error resetting password.", "error");
      }
    };

    const showToast = (message) => {
    toast({
    title: "Password Reset attempted",
    description: message,
    status: "success",
    duration: 2000,
    isClosable: true,
    });
    };
  return (
      <>
      <Header/>
      <Center minH="100vh" bg="gray.100" py={2}>
        <Box
          p={8}
          width={{ md: "sm", base: "full" }}
          maxW="md"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="xl"
          bg="white"
        >
          <VStack spacing={5}>
            <Text fontSize="3xl" fontWeight="bold" textAlign="center">
              Enter your New Password
            </Text>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="Enter your password.."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <FormControl id="confirmPassword">
              <FormLabel>Confirm Password</FormLabel>
              <Input
                type="password"
                placeholder="Confirm your password.."
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormControl>
            <Button
              className="bg-black"
              colorScheme="black"
              size="lg"
              width="full"
              onClick={() => handleClick()}
            >
              Change Password
            </Button>
          </VStack>
        </Box>
      </Center>
      <Footer/>
      </>

  )
}

export default ChangePassword;
