import React, { useState } from "react";
import {
  VStack,
  Input,
  FormControl,
  FormLabel,
  Button,
  Textarea,
  Center,
  Box,
  useToast,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { editUserAsync } from "../redux/actionCreators/thunks/userThunk";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const EditProfile = () => {
  let navigate = useNavigate();
  const userInfo = useSelector((state) => state.auth.user || {});
  const [username, setUsername] = useState(userInfo.name || "");
  const [email, setEmail] = useState(userInfo.email || "");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [description, setDescription] = useState(userInfo.description || "");
  const [phone, setPhone] = useState(userInfo.phone || "");
  const dispatch = useDispatch();
  const toast = useToast();

  const isEmailValid = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const handleIndividualSubmit = async (e) => {
    e.preventDefault(); // Prevent the form from submitting traditionally
    if (password !== confirmPassword) {
      toast({
        title: "Error",
        description: "Passwords do not match.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!isEmailValid(email)) {
      toast({
        title: "Error",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    let form = {};
    if (username) form.name = username;
    if (email) form.email = email;
    if (password) form.password = password;
    if (description) form.description = description;
    dispatch(editUserAsync(form, email));
    navigate("/dashboard");
  };
 
  return (
    <>
        <Center minH="100vh" bg="gray.100" p={4}>
          <Box
            as="form"
            p={{ base: 4, sm: 8 }} // Adjust padding as needed
            width="full"
            maxW="5xl" // Set a maximum width to make the form less wide
            borderWidth="1px"
            borderRadius="lg"
            boxShadow="xl"
            bg="white"
            mx="auto" // Add this to center the Box horizontally if not using <Center>
          >
            <VStack spacing={4} align="stretch">
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  placeholder="Name"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Repeat Password</FormLabel>
                <Input
                  type="password"
                  placeholder="Repeat Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>E-mail</FormLabel>
                <Input
                  type="email"
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  type="tel"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Profile Description</FormLabel>
                <Textarea
                  placeholder="Profile Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormControl>


              <Button
                type="submit"
                colorScheme="blue"
                bgColor="#000000"
                size="lg"
                w="full"
                onClick={handleIndividualSubmit}
              >
                Save Information
              </Button>
            </VStack>
          </Box>
        </Center>
    </>
  );
};

export default EditProfile;
