import React, { useState } from "react";
import {
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Link,
  Text,
  Center,
  Divider,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from "@chakra-ui/react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { registerAsync } from "../redux/actionCreators/thunks/authThunk";
import { useDispatch } from "react-redux";

const Register = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userType, setUserType] = useState("customer");
  const [phone, setPhone] = useState("");

  let navigate = useNavigate();
  const handleLoginClick = () => {
    navigate("/login"); // Use navigate to change the route
  };
  const toast = useToast();

  const isEmailValid = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the form from submitting traditionally

    if (password !== confirmPassword) {
      toast({
        title: "Error",
        description: "Passwords do not match.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!isEmailValid(email)) {
      toast({
        title: "Error",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    dispatch(
      registerAsync({
        name: username,
        email: email,
        password: password,
        type : userType,
        phone: phone,
        description: "",
        isSubscribed: userType==="customer" ? true : false,
        trialUsed: userType==="customer" ? true : false,
      })
    );

    navigate("/dashboard");
  };


  return (
    <>
      <Header />
      <Center minH="100vh" bg="gray.100" p={4}>
        <Box
          as="form"
          onSubmit={handleSubmit}
          p={8}
          width="full"
          maxW="md"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="xl"
          bg="white"
        >
          <VStack spacing={5}>
            <Text fontSize="3xl" fontWeight="bold" textAlign="center">
              Register an account
            </Text>
            <FormControl id="username">
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="Enter your Full Name.."
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormControl>
            <FormControl id="email">
              <FormLabel>Email Address</FormLabel>
              <Input
                type="email"
                placeholder="Enter your email address.."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="Enter your password.."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <FormControl id="confirmPassword">
              <FormLabel>Confirm Password</FormLabel>
              <Input
                type="password"
                placeholder="Confirm your password.."
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormControl>
              <FormControl id="phone">
                <FormLabel>Phone Number</FormLabel>
                <Input
                  type="tel"
                  placeholder="Enter your phone number..."
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </FormControl>
            <RadioGroup onChange={setUserType} value={userType}>
              <Stack direction="row" spacing={5}>
                <Radio value="transporter">Transporter</Radio>
                <Radio value="customer">Customer</Radio>
              </Stack>
            </RadioGroup>
            <Button bgColor="#000000" colorScheme="blue" size="lg" width="full" type="submit">
              REGISTER
            </Button>
            <Divider />
            <Link color="#424242" onClick={handleLoginClick}>
              Login here.
            </Link>
          </VStack>
        </Box>
      </Center>
      <Footer />
    </>
  );
};

export default Register;
