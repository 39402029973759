import React, { useState, useEffect } from 'react';
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  VStack,
  Button,
  Grid,
  useColorModeValue,
   Link,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  ModalHeader,
  Stack
} from '@chakra-ui/react';
import { fetchJobsAsync,deleteJobAsync,modifyJobAsync } from '../redux/actionCreators/thunks/jobsThunk';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { fetchProposalsAsync, deleteProposalAsync } from '../redux/actionCreators/thunks/proposalsThunk';
import { fetchUserAsync } from '../redux/actionCreators/thunks/userThunk';
const MyJobsList = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const jobsList = useSelector((state) => state.jobs.jobs || []);
  const userInfo = useSelector((state) => state.auth.user || {});
  const proposals = useSelector((state) => state.proposals.proposals.proposals || []);
  const user = useSelector((state) => state.user.user || {});
  const [jobs, setJobs] = useState([]);
  const [jobsid, setJobsid] = useState({});
  const { isOpen, onOpen: baseOnOpen, onClose } = useDisclosure();
  const [activeModalId, setActiveModalId] = useState(null);

  const onOpen = (job) => {
    dispatch(fetchUserAsync(job.givenBy));
    setActiveModalId(job._id);
    baseOnOpen();
  };
  const handleClose = () => {
    onClose();
    setActiveModalId(null); // Reset the active modal ID on close
  };
  const handleButtonClick = (job) => {
  navigate("/postproposal", { state: { job } });
};

  const handleQuoteDeleteClick = (job) => {
    let temp = jobsList.filter(obj => obj._id === job.jobId)[0];
    dispatch(modifyJobAsync(job.jobId, {quotes: temp.quotes -1}));
    dispatch(deleteProposalAsync(job._id));
    navigate("/dashboard");
};

const handleCompletedClick = (job) => {
  let form ={
    status:"Completed"
  }
   dispatch(modifyJobAsync(job._id, form));
   dispatch(fetchJobsAsync());
   navigate('/dashboard');
};
 const handleCancelClick = (job) => {
   dispatch(deleteJobAsync(job._id));
   dispatch(fetchJobsAsync());
   navigate('/dashboard');
};
function normalizeDateToUTC(inputDate) {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  
  return `${day}/${month}/${year}`;
}
  useEffect(() => {
    dispatch(fetchJobsAsync());
  },[]);
  useEffect(() => {
    const filteredJobs = jobsList.filter(job => job.createdBy[1] === userInfo.email);
    if (filteredJobs.length >0 ){
    let temp ={}
    filteredJobs.forEach(job => {
        dispatch(fetchProposalsAsync(job._id));
        let id = job._id; 
        let title = job.title;  
        temp[id] = title;
    });
    
    setJobs(filteredJobs);
    setJobsid(temp);
    }else{
      setJobs([]);
    }
    
  }, [dispatch]);

  
  const bgColor = useColorModeValue('gray.50', 'gray.900');

  const JobsHeader = () => {
  const bgColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.800', 'white');

  return (
    <Grid
      templateColumns={{base: "150px 150px 100px 200px", md:"1fr 1fr 1fr 1fr"}}
      bg={bgColor}
      p={2}
      alignItems="center"
      whiteSpace="nowrap"
      gap={4}
      borderRadius="md"
      minWidth="800px"
    >
      <Text fontWeight="semibold" color={textColor}>
        JOB TITLE
      </Text>
      <Text fontWeight="semibold" color={textColor}>
        DATE MADE
      </Text>
      <Text fontWeight="semibold" color={textColor}>
        QUOTES
      </Text>
      <Text fontWeight="semibold" color={textColor}>
        OPTIONS
      </Text>
    </Grid>
  );
};

const JobItem = ({ job }) => {
  const borderColor = useColorModeValue('gray.200', 'gray.700');
const bgColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.800', 'white');
  return (
    <>
    {(job.status==='Published') &&
    <Grid
      templateColumns={{base: "150px 150px 100px 1fr 200px", md:"1fr 1fr 1fr 1fr"}}
      p={2}
      alignItems="center"
      borderBottomWidth="1px"
      borderColor={borderColor}
      gap={{base:7,md:4}}
      minWidth="800px"
      
    >
      
      <Text color='red.500'>
        {job.title}
      </Text>
      <Text>
        {normalizeDateToUTC(job.postedDate)}
      </Text>
      <Text>
        {proposals.filter(temp => temp.jobId === job._id).length}
      </Text>
      <Stack direction ={{base:"row",md:"column"}}>
      <HStack w="full">
      <Button colorScheme="teal" size="sm" w="full" onClick={()=>handleButtonClick(job)}>
        View
      </Button>
      <Button colorScheme="teal" size="sm" w="full" onClick={()=>handleCancelClick(job)}>
        Cancel
      </Button>
      </HStack>
      <Button colorScheme="teal" size="sm" w="full" onClick={()=>handleCompletedClick(job)}>
        Job Completed
      </Button>
      </Stack>
    </Grid>}
    {(job.status==='Completed') &&
    <Grid
      templateColumns={{base: "150px 150px 100px 200px 200px", md:"1fr 1fr 1fr 1fr"}}
      p={2}
      alignItems="center"
      borderBottomWidth="1px"
      borderColor={borderColor}
      gap={2}
      minWidth="800px"
    >
      <Text color='red.500'>
        {job.title}
      </Text>
      <Text>
        {normalizeDateToUTC(job.postedDate)}
      </Text>
      <Text>
        {job.quotes}
      </Text>
      <Button colorScheme="teal" size="sm" onClick={()=>handleButtonClick(job)}>
        View
      </Button>
    </Grid>}
    </>
  );
};

 const ProposalHeader = () => {
  const bgColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.800', 'white');

  return (
    <Grid
      templateColumns={{base: "150px 150px 100px 400px 200px", md:"1fr 1fr 1fr 2fr 1fr"}}
      bg={bgColor}
      p={2}
      alignItems="center"
      gap={2}
      borderRadius="md"
       whiteSpace="nowrap"
       minWidth="1100px"
    >
      <Text fontWeight="semibold" color={textColor}>
        JOB TITLE
      </Text>
      <Text fontWeight="semibold" color={textColor}>
        QUOTE GIVEN BY
      </Text>
      <Text fontWeight="semibold" color={textColor}>
        QUOTE
      </Text>
      <Text fontWeight="semibold" color={textColor}>
        MESSAGE
      </Text>
      <Text fontWeight="semibold" color={textColor}>
        OPTION
      </Text>
    </Grid>
  );
};
const ProposalItem = ({ job, isOpen, onOpen, onClose }) => {
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Grid
      templateColumns={{base: "150px 150px 100px 400px 200px", md:"1fr 1fr 1fr 2fr 1fr"}}
      p={2}
      alignItems="center"
      borderBottomWidth="1px"
      borderColor={borderColor}
      gap={2}
      minWidth="800px"
    >
      <Text color='red.500'>
        {jobsid[job.jobId]}
      </Text>
      <Text>
        {job.givenBy}
      </Text>
      <Text>
      {job.quote} $
      </Text>
      <Text>
        {job.notes}
      </Text>      
      <HStack>
      <Button colorScheme="teal" size="sm" w="full" onClick={onOpen} >
        Contact User
      </Button>
      <Button colorScheme="teal" size="sm" w="full" onClick={() => handleQuoteDeleteClick(job)} >
        Delete Quote
      </Button>

      </HStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
            <ModalContent>
              
              <ModalHeader>Contact Information</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <HStack>
                  <Text fontWeight="bold">
                  Username:
                  </Text>
                  <Text >
                  {user.name}
                  </Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">
                  Email Address:
                  </Text>
                  <Link href={`mailto:${user.email}`} color="teal.500">
                    {user.email}
                  </Link>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">
                  Phone Number:
                  </Text>
                  <Link href={`tel:${user.phone}`} color="teal.500">
                    {user.phone}
                  </Link>
                </HStack>
              </ModalBody>

             
              <ModalFooter>
                <Button onClick={onClose}>Close</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
    </Grid>
  );
};
  return (
    <Box borderWidth="1px" borderRadius="lg" overflowX="auto" w="full" bg={bgColor} >
      <Tabs variant="line" isFitted >
        <TabList>
          <Tab _selected={{ color: 'white', bg: 'red.500' }}>Active Jobs</Tab>
          <Tab _selected={{ color: 'white', bg: 'red.500' }}>Active Quotes</Tab>
          <Tab _selected={{ color: 'white', bg: 'red.500' }}>Completed</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack spacing={4} align="stretch" overflowX="auto">
              <JobsHeader/>
              {(jobs.filter(job => job.status === "Published")).length === 0 ? (
                <Text>You do not have any active jobs.</Text>
              ) : (
                jobs.filter(job => job.status === "Published").map(job =>  <JobItem key={job._id} job={job} /> )
              )}
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack spacing={4} align="stretch" overflowX="auto">
              <ProposalHeader/>
              {(proposals.length === 0 || (jobs.filter(job => job.status === "Published")).length === 0) ? (
                <Text>You do not have any active quotes.</Text>
              ) : (
                proposals.map( job => jobsid[job.jobId] ? <ProposalItem key={job._id} job={job} isOpen={isOpen && activeModalId === job._id}
                        onOpen={() => onOpen(job)}
                        onClose={handleClose} />: null )
              )}
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack spacing={4} align="stretch" overflowX="auto">
              <JobsHeader/>
              {(jobs.filter(job => job.status === "Completed")).length === 0 ? (
                <Text>You do not have any completed jobs.</Text>
              ) : (
                jobs.filter(job => job.status === "Completed").map(job =>  <JobItem key={job._id} job={job} /> )
              )}
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default MyJobsList;
