import "../../constants";
import { addJob, deleteJob, fetchJobs, modifyJob } from "../jobsActions";
import { createFormData } from "./helperFunctions";

const dbUrl = process.env.REACT_APP_DB_URL;

export const fetchJobsAsync = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(dbUrl + "api/jobs", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const jobs = await response.json();
      if (response.ok) {
        dispatch(fetchJobs(jobs));
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };
};

export const addJobAsync = (job) => {
  return async (dispatch) => {
    try {
      const formData = createFormData(job);

      const response = await fetch(dbUrl + "api/jobs/add", {
        method: "POST",
        body: formData,
      });
      const newJob = await response.json();
      dispatch(addJob(newJob));
    } catch (error) {
      console.error("Error adding job:", error);
    }
  };
};

export const modifyJobAsync = (id, job) => {
  return async (dispatch) => {
    try {
      const formData = createFormData(job);

      const response = await fetch(dbUrl + `api/jobs/edit/${id}`, {
        method: "PUT",
        body: formData,
      });
      const modifiedJob = await response.json();
      dispatch(modifyJob(modifiedJob));
    } catch (error) {
      console.error("Error modifying job:", error);
    }
  };
};

export const deleteJobAsync = (id) => {
  return async (dispatch) => {
    try {
      await fetch(dbUrl + `api/jobs/delete/${id}`, {
        method: "DELETE",
      });
      dispatch(deleteJob(id));
    } catch (error) {
      console.error("Error deleting job:", error);
    }
  };
};
