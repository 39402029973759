import React from "react";
import {
  Text,
  Box,
  Flex,
  Button,
  IconButton,
  Collapse,
  Stack,
  useDisclosure,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
const DashboardNavBar = () => {
  const usersInfo = useSelector((state) => state.auth.user);
  let navigate = useNavigate();
  const location = useLocation();
  const { isOpen, onToggle } = useDisclosure();
  let buttons =  [
  { name: "Dashboard", path: "/dashboard" },
  { name: "Post New Job", path: "/postnewjob" },
  { name: "My Jobs", path: "/myjobs" },
  { name: "Profile Settings", path: "/profilesettings" },
] 

  const alignText = useBreakpointValue({ base: "left", md: "left" });
  
  return (
    <Box>
      <Flex direction="column" width="full" alignItems="center" bg="gray.100" >
        <Flex width="full" bg="gray.100" p={4} justify={alignText}>
          <Box>
            <Box bg="#424242" w="10px" h="100%" borderRadius="md" mr={2} />
          </Box>
          <Text fontSize="xl" fontWeight="bold" color="gray.800">
            WELCOME BACK, {usersInfo.name}
          </Text>
        </Flex>
        <Box
          bg="white"
          width="full"
          p={4}
          mt={2}
          borderRadius="md"
          justifyContent="left"
          wrap="wrap"
        >
          {/* Desktop Links */}
          <Flex
            align="center"
            justifyContent="space-evenly"
            width={{base:"auto",md:"full"}}
            bg="white"
          >
            <Text
              fontWeight="bold"
              color="gray.800"
              whiteSpace="nowrap"
              fontSize={{base:"lg",md:"2xl"}}
            >
              Account Menu
            </Text>
            <IconButton
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={"Open Menu"}
              display={{ base:"flex", md: "none" }}
              onClick={onToggle}
              ml={4}
            />
            <Stack
              direction={{ md: "row" }}
              spacing={4}
              color="gray.400"
              display={{ base: "none", md: "flex" }}
              width={"full"}
              justifyContent={"space-evenly"}
              wrap="wrap"
            >
              {buttons.map((item) => (
                <Button
                  key={item['name']} // Added key for list rendering
                  variant="ghost"
                  _hover={{ bg: "gray.500", color: "white" }}
                  fontWeight="bold"
                  fontSize="md"
                  color="gray.400"
                  onClick={()=>navigate(item['path'])}
                >
                  {item['name']}
                </Button>
              ))}
            </Stack>
          </Flex>
        </Box>
      </Flex>

      {/* Mobile Links */}
      <Collapse in={isOpen} animateOpacity>
        <Stack
          bg={"white"}
          p={4}
          display={{ md: "none" }}
          zIndex={2}
          width={"full"}
          direction="column"
        >
          {buttons.map((item) => (
            <Button
              key={item['name']} // Ensure each button has a unique key for list rendering
              variant="ghost"
              _hover={{ bg: "gray.500", color: "white" }}
              bg={location.pathname === item['path'] ? "RGB(242, 13, 13,1)" : "transparent"}
              fontWeight="bold"
              fontSize="md"
              width="full" // Ensure mobile buttons span the full width
              color={location.pathname === item['path'] ? "white" : "gray.400"}
              onClick={()=>navigate(item['path'])}
            >
              {item['name']}
            </Button>
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
};

export default DashboardNavBar;
