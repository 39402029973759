import React, { useEffect } from 'react';
import DashboardNavBar from '../components/DashboardNavBar'
import Footer from '../components/Footer'
import Header from '../components/Header'
import MyJobsList from '../components/MyJobsList'
import NavBar from '../components/NavBar'
const MyJobs = () => {
  return (
    <>
    <Header/>
    <NavBar/>
    <DashboardNavBar/>
    <MyJobsList/>
    <Footer/>
    </>
  )
}

export default MyJobs;
