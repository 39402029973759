import { ADD_JOB, DELETE_JOB, FETCH_JOBS, MODIFY_JOB } from "../constants";

const initialJobsState = {
    jobs: [],
};

const jobsReducer = (state = initialJobsState, action) => {
    switch (action.type) {
        case FETCH_JOBS:
            return {
                ...state,
                jobs: action.payload,
            };
        case ADD_JOB:
            return {
                ...state,
                jobs: [...state.jobs, action.payload],
            };
        case MODIFY_JOB:
            return {
                ...state,
                jobs: state.jobs.map(job =>
                    job.id === action.payload.id ? action.payload : job
                ),
            };
        case DELETE_JOB:
            return {
                ...state,
                jobs: state.jobs.filter(job => job.id !== action.payload),
            };
        default:
            return state;
    }
};

export default jobsReducer