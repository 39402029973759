import React, { useState } from "react";
import {
  Text,
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  CheckboxGroup,
  Checkbox,
  Heading,
  useToast,
  InputGroup,
  InputLeftAddon,
  Stack,
  Center,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import Autocomplete from "../components/AutoComplete";
import { modifyJobAsync } from "../redux/actionCreators/thunks/jobsThunk";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { LoadScript } from '@react-google-maps/api';
const EditJob = () => {
  let navigate = useNavigate();
    const location = useLocation();
    const { job } = location.state || {
    title: " ",
    imageUrl: " ",
    postedDate: " ",
    fromLocation: " ",
    toLocation: " ",
  };
  const usersInfo = useSelector((state) => state.auth.user);
  const [file, setFile] = useState(job.image);
  const [fileName, setFileName] = useState("");
  const [jobTitle, setJobTitle] = useState(job.title);
  const [jobDescription, setJobDescription] = useState(job.description);
  const [length, setLength] = useState(job.length);
  const [width, setWidth] = useState(job.width);
  const [height, setHeight] = useState(job.height);
  const [weight, setWeight] = useState(job.weight);
  const [numberOfPackages, setNumberOfPackages] = useState(job.numberOfPackages);
  const [pickupDate, setPickupDate] = useState(job.pickupDate);
  const [deliveryDate, setDeliveryDate] = useState(job.deliveryDate);
  const [selectedPickupMainRegion, setSelectedPickupMainRegion] = useState(job.pickupLocation[0]);
  const [selectedPickupSubRegion, setSelectedPickupSubRegion] = useState(job.pickupLocation[1] || null);
  const [selectedDeliveryMainRegion, setSelectedDeliveryMainRegion] = useState(job.deliveryLocation[0]);
  const [selectedDeliverySubRegion, setSelectedDeliverySubRegion] = useState(job.deliveryLocation[1] || null);
  const [deliveryAddress,setDeliveryAddress] = useState(job.deliveryAddress);
  const [pickupAddress,setPickupAddress] = useState(job.pickupAddress);

  const dispatch = useDispatch();

  const handleMainRegionChange = (mainCategory,setregion,setsubregion ,selectedMainRegion) => {
    if (selectedMainRegion === mainCategory) {
      setregion(null);
      setregion(null);
    } else {
      setregion(mainCategory);
      setsubregion(null); 
    }
  };
  const handleSubRegionChange = (subCategory,setregion) => {
    setregion(subCategory);
  };
  const initialRegions =[
    { mainCategory: "Victoria", subCategories: ["North VIC","East VIC","West VIC","Central-Greater Melbourne VIC"] },
    { mainCategory: "New South Wales", subCategories: ["South NSW","North NSW","West NSW","Greater Sydney NSW"] },
    { mainCategory:  "South Australia", subCategories: ["North SA","East SA","West SA","Greater Adelaide SA"] },
    { mainCategory: "Western Australia", subCategories: ["North WA", "Central WA", "South WA", "Greater Perth WA"] },
    { mainCategory: "Northern Territory", subCategories: ["North Greater Darwin NT", "Central NT", "South NT"] },
    { mainCategory: "Tasmania", subCategories:  []},
    { mainCategory: "Queensland", subCategories: ["North QLD","Far North QLD", "Central QLD", "South QLD", "SE Corner Greater Brisbane QLD"] },
  ]
  const toast = useToast();

  const handleSubmit = async (e) => {
    const googleMapsApiKey = "AIzaSyBtDGQnr6t1ZzQcP3CWfmi4nrC80m1Rfks";
    e.preventDefault();
    // Handle form submission logic here
    toast({
      title: "Job Posted",
      description: "Your job has been updated successfully.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    // You would send the state to your backend or state management store
    let dist = await findDistanceBetweenAddresses(pickupAddress,deliveryAddress,googleMapsApiKey)
      try {
        dist = dist["rows"][0]["elements"][0]["distance"]["text"].replace("km", "")
      }catch{
        dist = 0
      }
    let form = {
    title: jobTitle,
    description: jobDescription,
    postedDate: new Date(),
    image: file,
    length: length,
    width: width,
    weight: weight,
    height: height,
    numberOfPackages: numberOfPackages,
    pickupLocation: selectedPickupSubRegion ===null ? [selectedPickupMainRegion] : [selectedPickupMainRegion,selectedPickupSubRegion],
    pickupAddress:pickupAddress,
    deliveryLocation: selectedDeliverySubRegion===null ? [selectedDeliveryMainRegion]:[selectedDeliveryMainRegion,selectedDeliverySubRegion],
    deliveryAddress:deliveryAddress,
    pickupDate: pickupDate,
    deliveryDate: deliveryDate,
    distance:dist,
    createdBy: [usersInfo['name'],usersInfo['email'],usersInfo['avatar']]
  }
  // console.log(form)
  dispatch(modifyJobAsync(job._id, form));
  navigate('/dashboard')
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);       // Store the actual file object
      setFileName(selectedFile.name); // Store the file name for display
    }
  };
 const handleRemoveFile = () => {
    setFile(null);       // Clear the file object
    setFileName("");     // Clear the file name
  };


  async function findgeocodeAddress(address, googleMapsApiKey) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${googleMapsApiKey}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.status === 'OK') {
        return data.results[0].geometry.location; // Returns { lat, lng }
      } else {
        console.error('Geocoding failed:', data.error_message);
        return null;
      }
    } catch (error) {
      console.error('Geocoding request failed:', error);
      return null;
    }
  }
  

  async function getDistance(origin, destination, googleMapsApiKey) {
    const origins = `${origin.lat},${origin.lng}`;
    const destinations = `${destination.lat},${destination.lng}`;
    const baseUrl = process.env.REACT_APP_DB_URL;
  
    try {
     const response = await fetch(baseUrl + `getDistance?origins=${origins}&destinations=${destinations}&key=${googleMapsApiKey}`);
      const data = await response.json()
      return data; 
    } catch (error) {
      console.error('Error:', error);
      return null; 
    }
  }


async function findDistanceBetweenAddresses(address1, address2, googleMapsApiKey) {
  const location1 = await findgeocodeAddress(address1 + "Australia", googleMapsApiKey);
  const location2 = await findgeocodeAddress(address2 + "Australia", googleMapsApiKey);

  if (location1 && location2) {
    return await getDistance(location1, location2, googleMapsApiKey);
  } else {
    return null;
  }
}
  return (
    <>
      <Header />
      <NavBar />
      <Center minH="100vh" bg="gray.100" p={4}>
        <Box
          as="form"
          onSubmit={handleSubmit}
          p={{ base: 4, sm: 8 }} // Adjust padding as needed
          width="full"
          maxW="5xl" // Set a maximum width to make the form less wide
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="xl"
          bg="white"
          mx="auto" // Add this to center the Box horizontally if not using <Center>
        >
          <VStack onSubmit={handleSubmit} spacing={4} align="stretch">
            <Heading alignSelf={"center"} as="h3" size="lg">
              Edit Job
            </Heading>

              <FormLabel htmlFor="jobTitle">Your job title:</FormLabel>
              <Input
                id="jobTitle"
                placeholder="eg: I need a package moved."
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
              />

              <FormLabel htmlFor="jobDescription">
                Your job description:
              </FormLabel>
              <Textarea
                id="jobDescription"
                placeholder="Describe here your shipping job scope."
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
              />

            <Stack
              direction={{ base: "column", md: "row" }} // Stack vertically on small screens, horizontally on medium screens and up
              spacing={4} // Adjust spacing as needed
              width="full" // Ensure it takes the full width
            >
                <FormLabel htmlFor="length">Length:</FormLabel>
                <InputGroup>
                  <Input
                    id="length"
                    value={length}
                    onChange={(e) => setLength(e.target.value)}
                  />
                  <InputLeftAddon children="cm" />
                </InputGroup>

                <FormLabel htmlFor="width">Width:</FormLabel>
                <InputGroup>
                  <Input
                    id="width"
                    value={width}
                    onChange={(e) => setWidth(e.target.value)}
                  />
                  <InputLeftAddon children="cm" />
                </InputGroup>

                <FormLabel htmlFor="height">Height:</FormLabel>
                <InputGroup>
                  <Input
                    id="height"
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                  />
                  <InputLeftAddon children="cm" />
                </InputGroup>

                <FormLabel htmlFor="weight">Weight:</FormLabel>
                <InputGroup>
                  <Input
                    id="weight"
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                  />
                  <InputLeftAddon children="KG" />
                </InputGroup>
            </Stack>

              <FormLabel htmlFor="numberOfPackages">
                Number of packages:
              </FormLabel>
              <Input
                id="numberOfPackages"
                placeholder="eg: 2"
                value={numberOfPackages}
                onChange={(e) => setNumberOfPackages(e.target.value)}
              />


            
              

              <FormLabel htmlFor="pickupLocation">PACKAGE PICKUP Region</FormLabel>
                  <Box maxH="200px" overflowY="auto" borderWidth="1px" borderRadius="md" p={2}>
                    <VStack align="stretch" spacing={1}>
                      {initialRegions.map((category) => (
                        <Box key={category.mainCategory}>
                          <Checkbox
                            isChecked={selectedPickupMainRegion === category.mainCategory}
                            onChange={() => handleMainRegionChange(category.mainCategory,setSelectedPickupMainRegion,setSelectedPickupSubRegion ,selectedPickupMainRegion)}
                          >
                            {category.mainCategory}
                          </Checkbox>
                          {selectedPickupMainRegion === category.mainCategory && (
                            <Stack pl={4} mt={1} spacing={1}>
                              {category.subCategories.map((subCategory) => {
                                return (
                                  <Checkbox
                                    key={subCategory}
                                    isChecked={selectedPickupSubRegion === subCategory}
                                    onChange={() => handleSubRegionChange(subCategory,setSelectedPickupSubRegion)}
                                  >
                                    {subCategory}
                                  </Checkbox>
                                );
                              })}
                            </Stack>
                          )}
                        </Box>
                      ))}
                    </VStack>
                  </Box>


              <FormLabel htmlFor="pickupaddress">Pickup Address</FormLabel>    
            <LoadScript googleMapsApiKey={"AIzaSyBtDGQnr6t1ZzQcP3CWfmi4nrC80m1Rfks"} libraries={['places']}>              
            <Autocomplete value={pickupAddress} onChange={setPickupAddress} title="Pickup Address" />   
            </LoadScript>
            
              <FormLabel htmlFor="pickupDate">Pickup Date</FormLabel>
              <DatePicker
                id="pickupDate"
                selected={pickupDate}
                onChange={(date) => setPickupDate(date)}
                placeholderText="Click here to choose date"
                dateFormat="dd/MM/yyyy"
                customInput={
                  <Input
                    placeholder="Click here to choose date"
                    _hover={{ borderColor: "gray.200" }}
                    _focus={{ borderColor: "gray.200" }}
                    borderColor="gray.200"
                    w="full"
                  />
                }
              />
             <FormLabel htmlFor="deliveryLocation">
                PACKAGE DELIVERY REGION
              </FormLabel>
              <Box
                maxH="200px" // Adjust the height as needed
                overflowY="auto"
                borderWidth="1px"
                borderRadius="md"
                p={2}
              >
                <VStack align="stretch" spacing={1}>
                  <VStack align="stretch" spacing={1}>
                    {initialRegions.map((category) => (
                      <Box key={category.mainCategory}>
                        <Checkbox
                          isChecked={selectedDeliveryMainRegion === category.mainCategory}
                         onChange={() => handleMainRegionChange(category.mainCategory,setSelectedDeliveryMainRegion,setSelectedDeliverySubRegion ,selectedDeliveryMainRegion)}
                        >
                          {category.mainCategory}
                        </Checkbox>
                        {selectedDeliveryMainRegion === category.mainCategory && (
                        <Stack pl={4} mt={1} spacing={1}>
                          {category.subCategories.map((subCategory) => {
                            return (
                              <Checkbox
                                key={subCategory}
                                isChecked={selectedDeliverySubRegion === subCategory}
                                onChange={() => handleSubRegionChange(subCategory,setSelectedDeliverySubRegion)}
                              >
                                {subCategory}
                              </Checkbox>
                            );
                          })}
                        </Stack>
                        )}
                      </Box>
                    ))}
                  </VStack>
                </VStack>
              </Box>

            <FormLabel htmlFor="deliveryaddress">Delivery Address</FormLabel>      
            <LoadScript googleMapsApiKey={"AIzaSyBtDGQnr6t1ZzQcP3CWfmi4nrC80m1Rfks"} libraries={['places']}> 
            <Autocomplete value={deliveryAddress} onChange={setDeliveryAddress} title ="Delivery Address" /> 
            </LoadScript>

              <FormLabel htmlFor="deliveryDate">Delivery Date</FormLabel>
              <DatePicker
                id="DeliveryDate"
                selected={deliveryDate}
                onChange={(date) => setDeliveryDate(date)}
                placeholderText="Click here to choose date"
                dateFormat="dd/MM/yyyy"
                customInput={
                  <Input
                    placeholder="Click here to choose date"
                    _hover={{ borderColor: "gray.200" }}
                    _focus={{ borderColor: "gray.200" }}
                    borderColor="gray.200"
                    w="full"
                  />
                }
              />
            <Heading as="h4" size="md">
              Add Photos
            </Heading>
            <Text>
              Click the grey area below to add job images. Other files are not
              accepted. Use the form below.
            </Text>
            <Box
              p={5}
              borderWidth="2px"
              borderRadius="md"
              borderColor="gray.300"
              borderStyle="dashed"
              bg="gray.100"
              textAlign="center"
            >
              <FormLabel>
                <Input
                  type="file"
                  height="0"
                  width="0"
                  overflow="hidden"
                  onChange={handleFileChange}
                  accept="image/*" // Accept only images
                />
                <Text fontSize="lg" fontWeight="bold">
                  Click to upload files
                </Text>
                <Text fontSize="sm">{fileName || "No file selected"}</Text>
                {fileName && <Button onClick={handleRemoveFile}> Remove</Button>}
              </FormLabel>
            </Box>
            <Button colorScheme="blue" bgColor="#000000" type="submit">
              Post Job
            </Button>
          </VStack>
        </Box>
      </Center>
      <Footer />
    </>
  );
};

export default EditJob;
