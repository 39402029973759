import { fetchProposals, getProposals, addProposal, deleteProposal } from "../proposalsActions";

const dbUrl = process.env.REACT_APP_DB_URL;

export const fetchProposalsAsync = (jobID) => {
  return async (dispatch) => {
    try {
      const response = await fetch(dbUrl + `api/proposals/${jobID}`);
      if (!response.ok) {
        throw new Error("Failed to fetch proposals");
      }
      const proposals = await response.json();
      dispatch(fetchProposals(proposals));
    } catch (error) {
      console.log("Couldn't Fetch Proposals");
    }
  };
};

export const getProposalsAsync = (email) => {
  return async (dispatch) => {
    try {
      const response = await fetch(dbUrl + `api/proposals/user/${email}`);
      if (!response.ok) {
        throw new Error("Failed to fetch proposals");
      }
      const proposals = await response.json();
      dispatch(getProposals(proposals));
    } catch (error) {
      console.log("Couldn't Fetch Proposals");
    }
  };
};

export const addProposalAsync = (proposal, jobID) => {
  return async (dispatch) => {
    try {
      const response = await fetch(dbUrl + `api/proposals/${jobID}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(proposal),
      });
      if (response.ok) {
        const addedProposal = await response.json();
        dispatch(addProposal(addedProposal));
      }
    } catch (error) {
      console.log("Couldn't Add Proposal");
    }
  };
};

export const deleteProposalAsync = (proposalId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(dbUrl + `api/proposals/${proposalId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        dispatch(deleteProposal(proposalId));
      } else {
        console.log("Failed to delete proposal");
      }
    } catch (error) {
      console.log("Couldn't Delete Proposal");
    }
  };
};