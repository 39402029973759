import { legacy_createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";

// Redux-devtools extension library
import { composeWithDevTools } from "@redux-devtools/extension";

//importing reducer files
import authReducer from "./reducers/authReducer";
import jobsReducer from "./reducers/jobsReducer";
import proposalsReducer from "./reducers/proposalsReducer";
import userReducer from "./reducers/userReducer";
import notificationReducer from "./reducers/notificationReducer";

export const reducers = combineReducers({
  auth: authReducer,
  jobs: jobsReducer,
  proposals: proposalsReducer,
  user: userReducer,
  notification: notificationReducer,
});

export default legacy_createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk))
);
