import { LOGIN, LOGOUT, REGISTER } from '../constants';

export const login = (userData)=> ({
    type: LOGIN,
    payload: userData,
});

export const register = (userData)=> ({
    type: REGISTER,
    payload: userData,
});

// Action creator for logout
export const logout = () => ({
    type: LOGOUT,
});
