export const b64ToBlob = async (image) => {
  const blob = await base64StringToBlob(image);
  const file = new File([blob], "blob.png", { type: "image/png" });

  return file;
};

export const createFormData = (data) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
};

export const base64StringToBlob = async (base64String) => {
  const response = await fetch(base64String);
  const blob = await response.blob();
  return blob;
};
