import "./App.css";
import React, { useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import AllJobs from "./pages/AllJobs";
import PostNewJob from "./pages/PostNewJob";
import Dashboard from "./pages/Dashboard";
import PostProposal from "./components/PostProposal";
import ProfileSettings from "./pages/ProfileSettings";
import MyJobs from "./pages/MyJobs";
import EditJob from "./components/EditJobs";
import { initApp } from "./redux/actionCreators/thunks/authThunk";
import { useDispatch } from "react-redux";
import StripePayment from "./components/StripePayment";
import Subscribe from "./pages/Subscribe";
import RecoverPassword from "./pages/RecoverPassword";
import ChangePassword from "./pages/ChangePassword";
import GetDistace from "./pages/distance";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/alljobs",
    element: (
      <ProtectedRoute>
        <AllJobs />
      </ProtectedRoute>
    ),
  },
  {
    path: "/postnewjob",
    element: (
      <ProtectedRoute>
        <PostNewJob />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/postproposal",
    element: (
      <ProtectedRoute>
        <PostProposal />
      </ProtectedRoute>
    ),
  },
  {
    path: "/profilesettings",
    element: (
      <ProtectedRoute>
        <ProfileSettings />
      </ProtectedRoute>
    ),
  },
  {
    path: "/myjobs",
    element: (
      <ProtectedRoute>
        <MyJobs />
      </ProtectedRoute>
    ),
  },
  {
    path: "/editjob",
    element: (
      <ProtectedRoute>
        <EditJob />
      </ProtectedRoute>
    ),
  },
  {
    path: "/payment",
    element: ( <ProtectedRoute> <StripePayment /> </ProtectedRoute>),
  },
  {
    path: "/subscribe",
    element: (<ProtectedRoute> <Subscribe /></ProtectedRoute>),
  },
  {
    path: "/recoverpassword",
    element: (<RecoverPassword/>),
  },
  {path:"/resetpassword/:token", element:(<ChangePassword />)},
  {path:"/getdistance", element:(<GetDistace/>)},
  
  
  
]);

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initApp());
  }, []);
  return (
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
