import React from "react";
import { Flex, Button, useBreakpointValue } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";


const NavBar = () => {
  const usersInfo = useSelector((state) => state.auth.user);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const location = useLocation();  // Get the current location
  const displayValue = useBreakpointValue({ base: "none", md: "flex" });

  let buttons = [
    { name: "Home", path: "/" },
    { name: "All Jobs", path: "/alljobs" },
    { name: "My Account Area", path: "/dashboard" },
    { name: "Post New Job", path: "/postnewjob" },
  ];

     if (!isAuth) {
    buttons = buttons.map(button => ({ ...button, path: "/login" }));
  }

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <Flex
      as="nav"
      display={displayValue}
      alignItems="center"
      justifyContent="space-between"
      bg="white"
      boxShadow="lg"
      border="1px"
      borderColor="gray.200"
      w="full"
    >
      {buttons.map(({ name, path }) => (
        <Button
          key={name}
          flexGrow={1}
          borderRadius="0"
          bg={location.pathname === path ? "RGB(242, 13, 13,1)" : "transparent"}
          color={location.pathname === path ? "white" : "black"}
          onClick={() => handleButtonClick(path)}
          _hover={{
            bg: location.pathname === path ? "RGB(242, 13, 13,1)" : "gray.100",
            color: "black",
          }}
          _active={{
            bg: "RGB(242, 13, 13,1)",
            color: "white",
          }}
          height="100%"
          pt={2}
          pb={2}
        >
          {name}
        </Button>
      ))}
    </Flex>
  );
};


export default NavBar;
