import { ADD_NOTIFICATION, CLEAR_NOTIFICATION } from "../constants";

export const addNotification = (message) => ({
  type: ADD_NOTIFICATION,
  payload: message,
});

export const clearNotification = () => ({
  type: CLEAR_NOTIFICATION,
});
