import React, { useRef } from 'react';
import {
  Box,
  Button,
  Text,
  VStack,
  useBreakpointValue,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { FaTruck, FaRegListAlt, FaUsers } from "react-icons/fa";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Jobs from "../components/Jobs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NavBar from "../components/NavBar";

const Home = () => {
  // Adjust the size of the text and buttons based on the screen width
  const isAuth = useSelector((state) => state.auth.isAuthenticated)
  const textSize = useBreakpointValue({ base: "lg", md: "3xl" });
  const textSize_2 = useBreakpointValue({ base: "2xl", md: "4xl" });
  const buttonSize = useBreakpointValue({ base: "md", md: "lg" });
  const iconSize = useBreakpointValue({ base: "10", md: "10" }); // adjust icon size for responsive design
  let navigate = useNavigate();
  const jobsRef = useRef(null);
  const handleClick = (event) => {
      if (isAuth){      
          navigate("/dashboard")
      }else{
        navigate("/login"); 
      }
    };
    const handleJobClick = (event) => {
      if (isAuth){      
          navigate("/postnewjob")
      }else{
        navigate("/login"); 
      }
    };
     const handleScroll = () => {
    jobsRef.current.scrollIntoView({ behavior: 'smooth' });
  };
    
  return (
    <>
      <Header handleScroll={handleScroll}/>
      {isAuth && <NavBar/>}
      {/* First compponent */}
      <Box
        position="relative"
        height={{ base: "auto", md: "100vh" }}
        width="full"
        bgImage="url('/Hero-Image-1536x841.jpg')"
        bgSize="cover"
        bgPos="center"
        bgAttachment={{ base: "initial", md: "initial" }}
        alignContent={{md:"right"}}
      >
        <VStack
          position={{ base: "initial", md: "absolute" }} // Don't position absolutely on mobile
          top={{ base: "none", md: "30%" }}
          right={{ base: "none", md: "5%" }}
          // transform={{ base: "none", md: "translate(0%, -50%)" }} // Only apply translate on desktop
          spacing={6}
          align={{ base: "center", md: "right" }}
          width="full"
          p={{ base: 0, md: 8 }} // Less padding on mobile
          maxWidth={{ base: "full", md: "container.md" }} // Full width on mobile
        >
          <Box
            bg="RGBA(255, 255, 255, 0.40)" // Semi-transparent black background
            borderRadius="lg"
            p={6}
            textAlign={{ base: "center", md: "left" }}
            maxWidth="lg"
          >
            <Text
              fontSize={textSize}
              fontWeight="bold"
              color="black"
              fontFamily="Roboto, sans-serif"
            >
              Australian Transport Hub connects Clients with Carriers to get
              your goods delivered!
            </Text>
            <Text fontSize={{ base: "md", md: "lg" }} color="red" my={6}>
              Register now and submit your load for FREE!
            </Text>
            <Button
              bg={"RGB(242, 13, 13,1)"}
              colorScheme="red"
              size={buttonSize}
              m={2}
              minW={180}
              onClick={handleJobClick}
            >
              POST A NEW JOB
            </Button>
            <Button
              bg={"RGB(242, 13, 13,1)"}
              colorScheme="red"
              size={buttonSize}
              m={2}
              minW={180}
              onClick={handleClick}
            >
              BECOME A CARRIER
            </Button>
          </Box>
        </VStack>
      </Box>

      {/* Second compponent */}
      <Flex
        direction={{ base: "column", md: "row" }} // stack icons vertically on smaller screens, horizontally on medium+ screens
        justify="center" // center horizontally
        align="center" // center vertically
        wrap="wrap" // allow wrapping for smaller screens
        py={{ base: "3", md: "6" }} // vertical padding, more on larger screens
        bg="white" // background color
      >
        <VStack spacing="4" textAlign="center" w="full" maxW="sm">
          {" "}
          {/* maxWidth ensures wrapping */}
          <Icon as={FaRegListAlt} w={iconSize} h={iconSize} color="red.500" />
          <Text fontWeight="bold" fontSize={textSize}>
            Post Your Job
          </Text>
          <Text>
            You can easily post your transporting jobs for our verified
            transporters to bid on.
          </Text>
        </VStack>

        <VStack spacing="4" textAlign="center" w="full" maxW="sm">
          <Icon as={FaTruck} w={iconSize} h={iconSize} color="red.500" />
          <Text fontWeight="bold" fontSize={textSize}>
            Get Quotes
          </Text>
          <Text>
            After posting the job, wait for the best possible proposals in order
            to get the best service
          </Text>
        </VStack>

        <VStack spacing="4" textAlign="center" w="full" maxW="sm">
          <Icon as={FaUsers} w={iconSize} h={iconSize} color="red.500" />
          <Text fontWeight="bold" fontSize={textSize}>
            Pick Transporter
          </Text>
          <Text>
            Pick your favourite transporter based on budget, previous work and
            reviews
          </Text>
        </VStack>
      </Flex>

      {/* Thirdcompponent */}
      <Box
        position="relative"
        height={{ base: "auto", md: "100vh" }}
        width="full"
        bgImage="url('/highway-traffic-motion-blurred-truck-on-a-highwa-2021-09-01-00-24-30-utc.jpg')"
        bgSize="cover"
        bgPos="center"
        bgAttachment={{ base: "initial", md: "initial" }}
      >
        <VStack
          position={{ base: "initial", md: "absolute" }} // Don't position absolutely on mobile
          top={{ base: "none", md: "50%" }}
          right={{ base: "none", md: "5%" }}
          transform={{ base: "none", md: "translate(0%, -50%)" }} // Only apply translate on desktop
          spacing={6}
          align={{ base: "center", md: "right" }}
          width="full"
          p={{ base: 8, md: 8 }} // Less padding on mobile
          maxWidth={{ base: "full", md: "container.md" }} // Full width on mobile
        >
          <Box
            borderRadius="lg"
            p={6}
            textAlign={{ base: "center", md: "left" }}
            maxWidth="lg"
          >
            <Text
              fontSize={textSize_2}
              fontWeight="bold"
              color="white"
              fontFamily="Roboto, sans-serif"
            >
              Find the right transporter for your job
            </Text>
            <Text fontSize="lg" color="white" my={6}>
              If you have goods to be delivered, register your account and post
              your job with us, our registered and verified transporters connect
              with you and provide a quote to deliver your goods.
            </Text>
            <Button
              bg={"RGB(242, 13, 13,1)"}
              colorScheme="red"
              size={buttonSize}
              m={2}
              onClick={handleJobClick}
            >
              POST A JOB NOW
            </Button>
          </Box>
        </VStack>
      </Box>
      <div ref={jobsRef}>
        <Jobs />
      </div>
      <Footer />
    </>
  );
};

export default Home;
