import {
  Box,
  Flex,
  Text,
  VStack,
  Divider,
  useBreakpointValue,
  Icon,
  HStack,
  Stack,
} from "@chakra-ui/react";
import { LoadScript} from '@react-google-maps/api';
import { MdFlightTakeoff, MdFlightLand } from "react-icons/md";
import {
  FaRulerHorizontal,
  FaRulerVertical,
  FaWeight,
  FaBoxOpen,
  FaMapMarkerAlt,
  FaCalendar,
} from "react-icons/fa";
import Map from "./Map";
const JobView = ({job}) => {

function normalizeDateToUTC(inputDate) {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  
  return `${day}/${month}/${year}`;
}
  const maxWidth = useBreakpointValue({ base: "100%", md: "1000px" });
  const iconLabelWidth = "150px";
  return (
    <>
    <Box
      bg="white"
      maxWidth={maxWidth}
      borderWidth="1px"
      borderRadius="lg"
      p={5}
      boxShadow="sm"
      width="full"
    >
      <VStack
        divider={<Divider display={{ base: "none", md: "block" }} />}
        spacing={4}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          width="full"
        >
          <VStack align="start">
            <Text fontSize="lg" fontWeight="bold" color="#424242">
              <Icon as={MdFlightTakeoff} mr={2} /> Collection
            </Text>
            <Text fontSize="md">{job.pickupAddress}</Text>
            <Text fontSize="md" color="#424242">
              <Icon fontWeight="bold" color="#424242" as={FaCalendar} mr={2} />{" "}
              Pickup Date
            </Text>
            <Text fontSize="lg">{ normalizeDateToUTC(job.pickupDate )}</Text>
          </VStack>
          <VStack align="start">
            <Text fontSize="lg" fontWeight="bold" color="#424242">
              <Icon color="#424242" as={MdFlightLand} mr={2} /> Delivery
            </Text>
            <Text fontSize="md">{job.deliveryAddress}</Text>
            <Text fontWeight="bold" fontSize="md" color="#424242">
              <Icon color="#424242" as={FaCalendar} mr={2} /> Delivery Date
            </Text>
            <Text fontSize="lg">{ normalizeDateToUTC(job.deliveryDate)}</Text>
          </VStack>
        </Flex>
        
        <Box bg="white" w="full">
          <VStack spacing={4} align="stretch">
            {/* First row of items */}
            <Stack
              direction={{ base: "column", md: "row" }}
              justifyContent="space-between"
            >
              <Box minW={iconLabelWidth}>
                <HStack>
                  <Icon as={FaMapMarkerAlt} color="#424242" />
                  <Text fontWeight="bold" color="#424242">
                    Distance
                  </Text>
                </HStack>
                <Text>{job.distance}</Text>
              </Box>
              <Box minW={iconLabelWidth}>
                <HStack>
                  <Icon as={FaBoxOpen} color="#424242" />
                  <Text color="#424242" fontWeight="bold">
                    Packages
                  </Text>
                </HStack>
                <Text>{job.numberOfPackages}</Text>
              </Box>
              <Box minW={iconLabelWidth}>
                <HStack>
                  <Icon color="#424242" as={FaWeight} />
                  <Text color="#424242" fontWeight="bold">
                    Weight
                  </Text>
                </HStack>
                <Text>{job.weight} KG</Text>
              </Box>
            </Stack>

            <Divider display={{ base: "none", md: "block" }} />

            <LoadScript
            googleMapsApiKey={"AIzaSyBtDGQnr6t1ZzQcP3CWfmi4nrC80m1Rfks"}
            onError={(error) => console.error("Error loading Google Maps script:", error)}
          >
            <Map 
            job={job} />
            </LoadScript>
            {/* Divider */}
            <Box>
              <Box
                display={{ base: "none", md: "block" }}
                borderTopWidth="1px"
                borderColor="gray.200"
              />
            </Box>

            {/* Second row of items */}
            <Stack
              direction={{ base: "column", md: "row" }}
              justifyContent="space-between"
            >
              <Box minW={iconLabelWidth}>
                <HStack>
                  <Icon color="#424242" as={FaRulerHorizontal} />
                  <Text color="#424242" fontWeight="bold">
                    Width
                  </Text>
                </HStack>
                <Text>{job.width} cm</Text>
              </Box>
              <Box minW={iconLabelWidth}>
                <HStack>
                  <Icon color="#424242" as={FaRulerVertical} />
                  <Text color="#424242" fontWeight="bold">
                    Length
                  </Text>
                </HStack>
                <Text>{job.length} cm</Text>
              </Box>
              <Box minW={iconLabelWidth}>
                <HStack>
                  <Icon color="#424242" as={FaRulerVertical} />
                  <Text color="#424242" fontWeight="bold">
                    Height
                  </Text>
                </HStack>
                <Text>{job.height} cm</Text>
              </Box>
            </Stack>
          </VStack>
        </Box>
      </VStack>
    </Box>
    
    </>
  );
};

export default JobView;
