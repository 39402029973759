import { saveUser } from "../userActions";
import { login } from "../authActions";
import { createFormData } from "./helperFunctions";

const dbUrl = process.env.REACT_APP_DB_URL;

export const fetchUserAsync = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(dbUrl + `api/users/${id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch user details");
      }
      const userDetails = await response.json();
      dispatch(saveUser(userDetails));
    } catch (error) {
      console.log("Couldn't Fetch user");
    }
  };
};

export const editUserAsync = (updatedUser, id) => {
  return async (dispatch) => {
    try {
      const formData = createFormData(updatedUser);
      const response = await fetch(dbUrl + `api/users/${id}`, {
        method: "PUT",
        body: formData,
      });
      if (!response.ok) {
        throw new Error("Failed to edit user details");
      }
      const userDetails = await response.json();
      dispatch(login(userDetails));
    } catch (error) {
      console.log("Couldn't Fetch user");
    }
  };
};

export const startTrialAsync = (email) => {
  return async (dispatch) => {
    console.log("Starting trial for:", email)
    try {
      const response = await fetch(`${dbUrl}api/payment/trial`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error("Failed to start trial");
      }

      const reqResponse = await response.json();
      // console.log(reqResponse)
      dispatch(login(reqResponse.user));
      console.log("Trial Started")
    } catch (error) {
      console.log("Couldn't start trial:", error.message);
    }
  };
};
