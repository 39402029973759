import React from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  useBreakpointValue,
  Stack,
} from "@chakra-ui/react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import NavBar from "./NavBar";
import JobView from "../components/JobView";
import JobViewSideBar from "../components/JobViewSideBar";
import { useLocation } from "react-router-dom";
const PostProposal = () => {
  const location = useLocation();
  const { job } = location.state || {
    title: " ",
    imageUrl: " ",
    postedDate: " ",
    fromLocation: " ",
    toLocation: " ",
  };
 function normalizeDateToUTC(inputDate) {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  
  return `${day}/${month}/${year}`;
}

  return (
    <>
      <Header />
      <NavBar />
      <Box bg="gray.50">
        <VStack alignItems={"left"}>
          <Flex width="full" p={4} justify={"left"}>
            <Box>
              <Box bg="#424242" w="10px" h="100%" borderRadius="md" mr={2} />
            </Box>
            <Text fontSize="xl" fontWeight="bold" color="gray.800">
              {job.title}
            </Text>
          </Flex>
          <Text fontSize="sm" fontWeight="bold" color="gray.500" pl={4}>
            Posted On { normalizeDateToUTC(job.postedDate )}
          </Text>
        </VStack>
        <Stack
          direction={{ base: "column", md: "row" }}
          justifyContent="space-evenly"
          py="8"
        >
          <Box flex="1" maxW={{ md: "1000px" }}>
            <JobView job = {job}/> {/* Jobview component */}
            <Box p={5} bg="white" borderWidth="1px" borderRadius="lg" mt={5}>
              <Text fontSize="lg" mb={3}>
                ITEM MAIN DETAILS
              </Text>
              <Text fontSize="sm" color="gray.500">
                {job.description}
              </Text>
            </Box>
          </Box>

          <JobViewSideBar job ={job} />
        </Stack>
      </Box>
      <Footer />
    </>
  );
};

export default PostProposal;
