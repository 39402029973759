import { SAVE_USER } from "../constants";

const initialState = {
      name: "default",
      email: "default ",
      entity: "default ",
      companyName: "default",
      companyEmail: "default",
      companyPhone: "default",
      phone: "default",
      avatar: "default",
      description: "default",
    }
  

const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case SAVE_USER:
        return {
          ...state,
          user: action.payload,
        };
      default:
        return state;
    }
  };
  

export default userReducer;
