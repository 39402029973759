import { ADD_NOTIFICATION, CLEAR_NOTIFICATION } from "../constants";

const initialState = {
  message: null,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        message: action.payload,
      };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};

export default notificationReducer;
