import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  Button,
  FormControl,
  FormErrorMessage,
  VStack,
  Center,
  Text,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/actionCreators/authActions";

const dbUrl = process.env.REACT_APP_DB_URL;

const PaymentForm = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const usersInfo = useSelector((state) => state.auth.user);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        setError(error.message);
        setLoading(false);
      } else {
        console.log("PaymentMethod:", paymentMethod);

        // Make API request to send payment method and update user
        const response = await fetch(dbUrl + "api/payment", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentMethod: paymentMethod,
            userEmail: usersInfo.email,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to update user.");
        }

        const { user } = await response.json();
        console.log(user);
        dispatch(login(user));

        setLoading(false);
        setPaymentSuccess(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to process payment. Please try again.");
      setLoading(false);
    }
  };

  const handleGoHome = () => {
    navigateTo("/dashboard"); // Navigate to home page ("/dashboard")
  };

  return (
    <>
      {paymentSuccess ? (
        <VStack spacing="4">
          <Text fontSize="lg" color="green.500" textAlign="center">
            Payment successful! Thank you for your purchase.
          </Text>
          <Button onClick={handleGoHome} colorScheme="blue">
            Return to Home
          </Button>
        </VStack>
      ) : (
        <VStack
          as="form"
          spacing="4"
          align="stretch"
          onSubmit={handleSubmit}
          width="100%"
          maxWidth="400px"
        >
          <FormControl isInvalid={error} py={2}>
            <CardElement />
            <FormErrorMessage>{error}</FormErrorMessage>
          </FormControl>
          <Button
            type="submit"
            disabled={!stripe || loading}
            colorScheme="blue"
            bgColor="#000000"
          >
            {loading ? "Processing..." : "Pay Now"}
          </Button>
        </VStack>
      )}
    </>
  );
};

export default PaymentForm;
