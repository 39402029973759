import React, { useState,useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';
import {  useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchJobsAsync } from "../redux/actionCreators/thunks/jobsThunk";
const DashboardJobsPreview = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const jobsList = useSelector((state) => state.jobs.jobs);
  const usersInfo = useSelector((state) => state.auth.user);
  const [jobs, setJobs] = useState([]);
  // Your jobs data
  
   useEffect(() => {
    dispatch(fetchJobsAsync());
    let filteredJobs =[];
    if (jobsList.length >0){
        filteredJobs = jobsList.filter(job => job.createdBy[1] === usersInfo.email);
    }
    setJobs(filteredJobs);
  }, []);

   useEffect(() => {
    let filteredJobs =[];
    if (jobsList.length >0){
        filteredJobs = jobsList.filter(job => job.createdBy[1] === usersInfo.email);
    }
    setJobs(filteredJobs);
  }, [jobsList]);

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" w="full" bg="white" maxHeight={{base:'none',md:450}}>
      <Box p={6}>
        <Heading size="md" mb={4}>
          LATEST POSTED JOBS
        </Heading>
        <Box overflowX="auto"> {/* Make this Box scrollable horizontally */}
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>JOB NAME</Th>
                <Th isNumeric>QUOTES</Th>
                <Th>OPTIONS</Th>
              </Tr>
            </Thead>
            <Tbody>
              {jobs.map((job) => (
                <Tr key={job._id}>
                  <Td>
                    <Text fontWeight="bold">{job.title}</Text>
                    <Text fontSize="sm">{job.description}</Text>
                  </Td>
                  <Td isNumeric>{job.quotes}</Td>
                  <Td>
                    <Button rightIcon={<ViewIcon />} size="sm" colorScheme="blackAlpha" bg="black" onClick={()=>navigate('/myjobs')}>
                      View Job
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
        <Link color="blue.500" href="#" fontSize="sm"  onClick={()=>navigate('/myjobs')} >
          + View all jobs
        </Link>
      </Box>
    </Box>
  );
};

export default DashboardJobsPreview;
