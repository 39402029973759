import React from 'react'
import DashboardNavBar from '../components/DashboardNavBar'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { Box, Stack} from '@chakra-ui/react';
import UserProfile from '../components/UserProfile'
import DashboardJobsPreview from '../components/DashboardJobsPreview'
import { useSelector } from "react-redux";
import DashboardQuotes from '../components/DashboardQuotes'
const Dashboard = () => {
  const usersInfo = useSelector((state) => state.auth.user);
  return (
    <>
     <Header/> 
     <NavBar/>
     <Box bg="gray.100">
     <DashboardNavBar/>
     <Stack direction={{base:"column",md:"row"}} justifyContent="space-evenly" py={8} px={5}>
     <UserProfile/>
     {usersInfo["type"] === "customer" && <DashboardJobsPreview/>}
     {usersInfo["type"] !== "customer" && <DashboardQuotes/>}
     </Stack>
     </Box>
     <Footer/> 
    </>
  )
}

export default Dashboard
