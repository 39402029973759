import { FETCH_PROPOSALS, GET_PROPOSALS, ADD_PROPOSAL, DELETE_PROPOSAL } from "../constants";

const initialState = {
  proposals: [],
};

const proposalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROPOSALS:
      return {
        ...state,
        proposals: action.payload,
      };
    case GET_PROPOSALS:
      return {
        ...state,
        proposals: action.payload,
      };
    case ADD_PROPOSAL:
      return {
        ...state,
        proposals: [...state.proposals, action.payload],
      };
    case DELETE_PROPOSAL:
      return {
        ...state,
        proposals: state.proposals.filter(
          (proposal) => proposal._id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default proposalsReducer;
