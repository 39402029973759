import React from 'react'
import DashboardNavBar from '../components/DashboardNavBar'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { Box} from '@chakra-ui/react';
import EditProfile from '../components/EditProfile'
const ProfileSettings = () => {
  return (
    <>
     <Header/> 
     <NavBar/>
     <Box bg="gray.100">
     <DashboardNavBar/>
    <EditProfile/>
     </Box>
     <Footer/> 
    </>
  )
}

export default ProfileSettings
