import React, { useState } from "react";
import Footer from '../components/Footer'
import Header from '../components/Header'
import {
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Center,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const dbUrl = process.env.REACT_APP_DB_URL;

const RecoverPassword = () => {
    let navigate = useNavigate();
    const toast = useToast();
    const [email, setEmail] = useState("");

    const handleClick = async () => {
      try {
        const response = await fetch(dbUrl + "api/auth/forgotpassword", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        });
  
        if (response.ok) {
          const data = await response.json();
          showToast(data.message || "Email has been sent.");
        } else {
          const errorData = await response.json();
          showToast(errorData.error || "Error sending email.", "error");
        }
      } catch (error) {
        showToast("Error sending email.", "error");
      }
    };

    const showToast = () => {
    toast({
    title: "Email Sent",
    description: "An email has been sent to your Email Address to recover your password",
    status: "success",
    duration: 2000,
    isClosable: true,
    });
    };
  return (
      <>
      <Header/>
      <Center minH="100vh" bg="gray.100" py={2}>
        <Box
          p={8}
          width={{ md: "sm", base: "full" }}
          maxW="md"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="xl"
          bg="white"
        >
          <VStack spacing={5}>
            <Text fontSize="3xl" fontWeight="bold" textAlign="center">
              Enter your Email
            </Text>
            <FormControl id="username">
              <FormLabel>Email</FormLabel>
              <Input
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email.."
              />
            </FormControl>
            <Button
              className="bg-black"
              colorScheme="black"
              size="md"
              width="full"
              onClick={() => handleClick()}
            >
              Recover Password
            </Button>
            <Button
              className="bg-black"
              colorScheme="black"
              size="md"
              width="full"
              onClick={() => navigate('/login')}
            >
              Back to Login
            </Button>
          </VStack>
        </Box>
      </Center>
      <Footer/>
      </>

  )
}

export default RecoverPassword;
