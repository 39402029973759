export const FETCH_JOBS = "FETCH_JOBS";
export const ADD_JOB = "ADD_JOB";
export const MODIFY_JOB = "MODIFY_JOB";
export const DELETE_JOB = "DELETE_JOB";

export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const LOGOUT = "LOGOUT";

export const FETCH_PROPOSALS = "FETCH_PROPOSALS";
export const GET_PROPOSALS = "GET_PROPOSALS";
export const ADD_PROPOSAL = "ADD_PROPOSAL";
export const DELETE_PROPOSAL = 'DELETE_PROPOSAL';

export const SAVE_USER = "SAVE_USER";

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
