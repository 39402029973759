import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Image,
  Text,
  Button,
  Divider,
  Flex,
} from "@chakra-ui/react";
import { AtSignIcon, ChatIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const dbUrl = process.env.REACT_APP_DB_URL;

const UserProfile = () => {
  const jobsList = useSelector((state) => state.jobs.jobs);
  const [jobs, setjobs] = useState(0);
  let navigate = useNavigate();
  const usersInfo = useSelector((state) => state.auth.user);
  useEffect(() => {
    const filteredJobs = jobsList.filter(
      (job) => job.createdBy[1] === usersInfo.email
    );
    setjobs(filteredJobs.length);
  }, []);
  return (
    <Box
      px={{ base: 4, md: 8 }}
      width="full"
      maxW={{ base: "sm", sm: "sm", md: "md" }}
      alignItems={{ base: "center", md: "left" }}
    >
      <Box
        maxW="sm"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={{ base: 4, md: 6 }}
        bg="white"
        mx="auto" // Centers the box
      >
        <Stack spacing={4} align="center">
          <Text fontSize="xl" fontWeight="bold">
            {usersInfo.name}
          </Text>
          <Text textAlign="center">
            {usersInfo.description
              ? usersInfo.description
              : "There is no Profile Bio defined"}
          </Text>
          
          {usersInfo["type"] === "customer" && 
          <> 
          <Divider my={4} />
          <Flex justify="space-between" width="full">
            <Flex align="center">
              <AtSignIcon mr={2} />
              <Text>Jobs in progress</Text>
            </Flex>
            <Text fontWeight="bold">{jobs}</Text>
          </Flex>
          <Divider my={4} />
          </>
            }
          <Button
            colorScheme={"black"}
            bg="black"
            width="full"
            mt={4}
            onClick={() => navigate("/profilesettings")}
          >
            Edit Profile
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default UserProfile;
