import React, { useState,useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';
import {  useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchJobsAsync, modifyJobAsync  } from "../redux/actionCreators/thunks/jobsThunk";
import {getProposalsAsync, deleteProposalAsync } from "../redux/actionCreators/thunks/proposalsThunk"
const DashboardQuotes = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const usersInfo = useSelector((state) => state.auth.user);
  const proposals = useSelector((state) => state.proposals.proposals.proposals || []);
  const jobsList = useSelector((state) => state.jobs.jobs);
  const [jobs, setJobs] = useState([]);
   useEffect(() => {
    dispatch(fetchJobsAsync());
   dispatch(getProposalsAsync(usersInfo["email"]));
  }, []);
    useEffect(() => {
    setJobs(proposals);
  }, [proposals]);
  
   useEffect(() => {
    dispatch(getProposalsAsync(usersInfo["email"]));
  }, [dispatch]);
    const handleQuoteDeleteClick = (job) => {
      try{
        let temp = jobsList.filter(obj => obj._id === job.jobId)[0];
        dispatch(modifyJobAsync(job.jobId, {quotes: temp.quotes -1}));
        }catch{
          
        }
        dispatch(deleteProposalAsync(job._id));
        navigate("/dashboard");
        setJobs(jobs.filter(obj => obj._id !== job._id));
    };
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" w="full" bg="white" maxHeight={{base:'none',md:450}}>
      <Box p={6}>
        <Heading size="md" mb={4}>
        Posted Quotes
        </Heading>
        <Box overflowX="auto" overflowY="auto"> {/* Make this Box scrollable horizontally */}
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>JOB NAME</Th>
                <Th>MESSAGE</Th>
                <Th >QUOTE</Th>
                <Th>OPTIONS</Th>
              </Tr>
            </Thead>
            <Tbody>
              {jobs.map((job) => (
                <Tr key={job._id}>
                  <Td>
                    <Text fontWeight="bold">
                    {jobsList.filter(item => item["_id"] === job["jobId"])[0]?.title || "Job has been canceled"}
                  </Text>
                  </Td>
                  <Td >
                    <Box whiteSpace="normal" wordBreak="break-word">
                      <Text fontSize="sm">{job.notes}</Text>
                    </Box>
                  </Td>
                  <Td >{job.quote} $</Td>
                  <Td>
                    <Button  size="sm" colorScheme="blackAlpha" bg="black" onClick={()=>handleQuoteDeleteClick(job)}>
                      Delete Quote
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardQuotes;
