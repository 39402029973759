// ProtectedRoute.js (You can create this in your components folder)
import React from 'react';
import { useSelector } from 'react-redux'; // Assuming Redux is used
import { Navigate, useLocation } from 'react-router-dom';

function ProtectedRoute({ children }) {
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const usersInfo = useSelector((state) => state.auth.user);
  const location = useLocation();
  if (!isAuth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }else if (location.pathname ==="/subscribe"){
    return children;
  }
  else if (isAuth && usersInfo.isSubscribed === false){
    return <Navigate to="/subscribe"  replace />;
  } 

  return children;
}
export default ProtectedRoute;