import React, { useState, useEffect } from "react";
import {
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Checkbox,
  Link,
  Text,
  Center,
  Divider,
  useToast,
} from "@chakra-ui/react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { loginAsync } from "../redux/actionCreators/thunks/authThunk";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchJobsAsync } from "../redux/actionCreators/thunks/jobsThunk";
import { clearNotification } from "../redux/actionCreators/notificationActions";
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const usersInfo = useSelector((state) => state.auth.user);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const message = useSelector((state) => state.notification.message);
  const toast = useToast();
  let auth = true;
  const [loginAttempt, setLoginAttempt] = useState(false);
  let navigate = useNavigate();
  const handleRegisterClick = () => {
    navigate("/register"); // Use navigate to change the route
  };
  async function handleLogin() {
    dispatch(loginAsync({ email: username, password: password }));
    setLoginAttempt(true);
  }

  useEffect(() => {
    if (isAuth) {
      auth = false;
      dispatch(fetchJobsAsync());
      navigate("/alljobs");
    }
  }, [isAuth === true && auth === true]);

  const showToast = () => {
    toast({
      title: "Login Failed",
      description: "Wrong credentials",
      status: "warning",
      duration: 2000,
      isClosable: true,
      onCloseComplete: () => {
        dispatch(clearNotification()); // Dispatch an action when the toast closes
      },
    });
  };
  return (
    <>
      <Header />
      {message && showToast()}
      <Center minH="100vh" bg="gray.100" py={2}>
        <Box
          p={8}
          width={{ md: "sm", base: "full" }}
          maxW="md"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="xl"
          bg="white"
        >
          <VStack spacing={5}>
            <Text fontSize="3xl" fontWeight="bold" textAlign="center">
              Login to your account
            </Text>
            <FormControl id="username">
              <FormLabel>Email</FormLabel>
              <Input
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter your email.."
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Enter your password.."
              />
            </FormControl>
            <Checkbox colorScheme="blue" size="lg">
              Keep me logged in
            </Checkbox>
            <Button
              className="bg-black"
              colorScheme="black"
              size="lg"
              width="full"
              onClick={() => handleLogin()}
            >
              SIGN IN
            </Button>
            <Divider />
            <VStack>
              <Link onClick={handleRegisterClick} color="#424242" href="#">
                Register here.
              </Link>
              <Link color="#424242" href="#" onClick={()=>navigate('/recoverpassword')}>
                Recover your password
              </Link>
            </VStack>
          </VStack>
        </Box>
      </Center>
      <Footer />
    </>
  );
};

export default Login;
