import { FETCH_PROPOSALS, GET_PROPOSALS, ADD_PROPOSAL, DELETE_PROPOSAL } from "../constants";

export const fetchProposals = (proposals) => ({
  type: FETCH_PROPOSALS,
  payload: proposals,
});

export const getProposals = (proposals) => ({
  type: GET_PROPOSALS,
  payload: proposals,
});

export const addProposal = (proposal) => ({
  type: ADD_PROPOSAL,
  payload: proposal,
});

export const deleteProposal = (proposalId) => ({
  type: DELETE_PROPOSAL,
  payload: proposalId,
});