import { login, logout, register } from "../authActions";
import { clearNotification } from "../notificationActions";
import { addNotification } from "../notificationActions";
import { createFormData } from "./helperFunctions";

const dbUrl = process.env.REACT_APP_DB_URL;

export const initApp = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(dbUrl + "api/auth/init", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const user = await response.json();
      if (response.ok) {
        dispatch(clearNotification());
        dispatch(login(user));
      }
    } catch (error) {
      // Handle error
      console.error("Error logging in:", error);
    }
  };
};

export const loginAsync = (userData) => {
  return async (dispatch) => {
    try {
      const response = await fetch(dbUrl + "api/auth/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });
      const user = await response.json();
      if (response.ok) {
        dispatch(clearNotification());
        dispatch(login(user));
      }else{
        dispatch(addNotification(`Error logging in: ${response}`));
      }
    } catch (error) {
      dispatch(addNotification(`Error logging in: ${error}`));
      console.error("Error logging in:", error);
    }
  };
};

export const registerAsync = (userData) => {
  return async (dispatch) => {
    try {
      const response = await fetch(dbUrl + "api/auth/register", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        const user = await response.json();
        dispatch(clearNotification());
        dispatch(register(user));
      } else {
        dispatch(
          addNotification(`Error Regsitering User: ${response.statusText}`)
        );

        console.error("Error registering user:", response.statusText);
      }
    } catch (error) {
      dispatch(addNotification(`Error Regsitering User: ${error}`));
      console.error("Error registering user:", error);
    }
  };
};

export const logoutAsync = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(dbUrl + "api/auth/logout", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        dispatch(logout());
      }
      dispatch(logout());
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };
};
