import React, {useState} from "react";
import {
  Box,
  VStack,
  Text,
  Button,
  Image,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Link,
  ModalBody,
  FormControl,
  FormLabel,
   Checkbox,
  Input,
  Textarea,
  ModalFooter,
  useDisclosure,
  ModalHeader,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { addProposalAsync } from "../redux/actionCreators/thunks/proposalsThunk";
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserAsync } from '../redux/actionCreators/thunks/userThunk';
import { modifyJobAsync } from "../redux/actionCreators/thunks/jobsThunk";
const JobViewSideBar = ({job}) => {
  const dispatch = useDispatch();
  const usersInfo = useSelector((state) => state.auth.user || "");
  const jobsList = useSelector((state) => state.jobs.jobs || []);
  const [isGstIncluded, setIsGstIncluded] = useState(false);
  let navigate = useNavigate();
  const user = useSelector((state) => state.user.user || "");
  const [quote, setQuote] = useState("");
  const [description, setDescription] = useState("");
  const dbUrl = process.env.REACT_APP_DB_URL;
  const handleButtonClick = (job) => {
  navigate("/editjob", { state: { job } });
};
 const handleCheckboxChange = (e) => {
    setIsGstIncluded(e.target.checked);
  };
 const handleContactClick = (job) => {
   dispatch(fetchUserAsync(job.createdBy[1]));
   onOpenModal2();
   console.log(usersInfo);;
};
  const { isOpen: isOpenModal1, onOpen: onOpenModal1, onClose: onCloseModal1 } = useDisclosure();
    const { isOpen: isOpenModal2, onOpen: onOpenModal2, onClose: onCloseModal2 } = useDisclosure();
  const handleQuoteClick = (job) =>{
    onCloseModal1();
    let updated_description = description;
    if (isGstIncluded){
      updated_description = description +" " + "(GST is included in the Quote.)"
    }
    
    let email = usersInfo.email;
    let form = {
      quote:quote,
      givenBy:email,
      notes:updated_description,
      jobId: job._id
    }
    dispatch(addProposalAsync(form));
    let temp = jobsList.filter(obj => obj._id === job._id)[0];
    // console.log(temp.quotes +1)
    dispatch(modifyJobAsync(job._id, {quotes: temp.quotes +1}))
    // console.log(isGstIncluded,form);
  }
  return (
    <VStack spacing={5} align="left" maxW={"400px"}>
      {/* Box 1: Job Ownership */}
      {( job.createdBy[1]=== usersInfo["email"] && job.status==="Published") && (
        <Box p={5} bg="white" borderWidth="1px" borderRadius="lg">
          <Text textAlign={"center"} fontSize="sm" mb={3}>
            You are the owner of this job
          </Text>
          <Button
            color="white"
            bg="black"
            size="sm"
            variant="outline"
            width="full"
            onClick={()=>handleButtonClick(job)}
          >
            Edit Job
          </Button>
        </Box>
      )}
      {( usersInfo["type"] !=="customer" && job.createdBy[1]!== usersInfo["email"] && job.status==="Published") && (
        <Box p={5} bg="white" borderWidth="1px" borderRadius="lg">
          <Button
            onClick={onOpenModal1}
            color="white"
            bg="green"
            size="sm"
            variant="outline"
            width="full"
          >
            Submit a quote
          </Button>

          <Modal blockScrollOnMount={false} isOpen={isOpenModal1} onClose={onCloseModal1}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Submit a quote for this job</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel>Your price:</FormLabel>
                  <Input type="number" onChange={(e) => setQuote(e.target.value)} placeholder="$" />
                </FormControl>
                <FormControl mt={4}>
                <Checkbox onChange={handleCheckboxChange} isChecked={isGstIncluded}>
                  GST Included
                </Checkbox>
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Description:</FormLabel>
                  <Textarea placeholder="Describe here..."  onChange={(e) => setDescription(e.target.value)} />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={() =>handleQuoteClick(job)}>
                  Submit your quote
                </Button>
                <Button onClick={onCloseModal1}>Close</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      )}

      {/* Box 2: User Profile */}
      {usersInfo["type"] !=="customer" && job.createdBy[1]!== usersInfo["email"] &&
      <Box p={5} bg="white" borderWidth="1px" borderRadius="lg">
        <Text textAlign="center" fontSize="xl" fontWeight="bold" mt={3}>
          {job.createdBy[0]}
        </Text>
        <Text textAlign="center" fontSize="sm" color="gray.500" mb={3}>
          {usersInfo["description"] || "no profile description"}
        </Text>
        <Button color="white" bg="black" size="sm" width="full" onClick={()=>handleContactClick(job)}>
          Contact User
        </Button>
         <Modal isOpen={isOpenModal2} onClose={onCloseModal2}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Contact Information</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <HStack>
                  <Text fontWeight="bold">
                  Name:
                  </Text>
                  <Text >
                  {user.name}
                  </Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">
                  Email Address:
                  </Text>
                  <Link href={`mailto:${user.email}`} color="teal.500">
                    {user.email}
                  </Link>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">
                  Phone Number:
                  </Text>
                  <Link href={`tel:${user.phone}`} color="teal.500">
                    {user.phone}
                  </Link>
                </HStack>
              </ModalBody>     
              <ModalFooter>
                <Button onClick={onCloseModal2}>Close</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

      </Box>
}


      {/* Box 4: Photos */}
      <Box p={5} bg="white" borderWidth="1px" borderRadius="lg">
        <Text fontSize="lg" mb={3}>
          ITEM PHOTOS
        </Text>
        {job.image ? (
        <Image src={dbUrl + job.image.replace(/\\/g, '/')} alt="Job image" borderRadius="md" />
      ) : (
        <Text fontSize="sm" color="gray.500">
          There are no pictures attached.
        </Text>
      )}

      </Box>
    </VStack>
  );
};

export default JobViewSideBar;