import React, { useState, useCallback, useRef } from 'react';
import { Autocomplete as GoogleAutocomplete } from '@react-google-maps/api';
import { Input, InputGroup } from '@chakra-ui/react';

const Autocomplete = ({ value, onChange, title }) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const autocompleteRef = useRef(null); // Use ref to ensure we don't create multiple instances

  // Callback function to handle place selection
  const onPlaceChanged = useCallback(() => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place && place.formatted_address) {
        onChange(place.formatted_address || place.name);
      } else {
        console.error("No address found");
      }
    } else {
      console.error("Autocomplete instance is not set");
    }
  }, [onChange]);

  // Set the Autocomplete instance
  const onLoad = useCallback((autocomplete) => {
    console.log("Autocomplete loaded");
    if (autocompleteRef.current) {
      return;
    }
    autocompleteRef.current = autocomplete;
    autocomplete.addListener('place_changed', onPlaceChanged);
  }, [onPlaceChanged]);

  return (
    <GoogleAutocomplete
      onLoad={onLoad}
      options={{
        componentRestrictions: { country: 'au' }, // Restrict to Australia
        fields: ['formatted_address', 'geometry'], // Specify the fields to return
        types: ['address'], // Restrict to address results
      }}
    >
      <InputGroup>
        <Input
          id={title}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Enter an address"
        />
      </InputGroup>
    </GoogleAutocomplete>
  );
};

export default Autocomplete;
