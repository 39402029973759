import {
  Flex,
  HStack,
  VStack,
  useBreakpointValue,
  Box,
} from "@chakra-ui/react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Jobs from "../components/Jobs";
import JobsList from "../components/JobsList";
import NavBar from "../components/NavBar";
import Search from "../components/Search";

const AllJobs = () => {
  // Determine whether to display components horizontally or vertically based on screen size
  const isHorizontal = useBreakpointValue({ base: false, lg: true });
  
  return (
    <>
    <Header/>
    <NavBar/>
    <Jobs/>
    <Footer/>

    </>
  );
};

export default AllJobs;
