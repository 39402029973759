import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '500px'
};

const googleMapsApiKey = "AIzaSyBtDGQnr6t1ZzQcP3CWfmi4nrC80m1Rfks"; // Make sure to replace it with your actual API key

const Map = ({ job }) => {
  const [locations, setLocations] = useState({ pickupLocation: null, dropoffLocation: null });

  useEffect(() => {
    if (job) {
      geocodeAddress(job.pickupAddress + 'Australia', 'pickup');
      geocodeAddress(job.deliveryAddress + 'Australia', 'dropoff');
     
    }
  }, [job]);
 
  const geocodeAddress = async (address, label) => {
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${googleMapsApiKey}`);
      const data = await response.json();
      
      if (data.status === "OK") {
        const coords = data.results[0].geometry.location;
        setLocations(prev => ({
          ...prev,
          [label === 'pickup' ? 'pickupLocation' : 'dropoffLocation']: coords
        }));
      } else {
        console.error(`Geocoding failed for ${label}:`, data.status);
      }
    } catch (error) {
      console.error(`Failed to geocode ${label} address`, error);
    }
  };

  const { pickupLocation, dropoffLocation } = locations;

  if (!pickupLocation || !dropoffLocation) {
    return <Box>Loading map...</Box>;
  }

  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={ pickupLocation || dropoffLocation}
        zoom={5}
      >
       <Marker position={pickupLocation} label="Pickup Location" />
      <Marker position={dropoffLocation} label="Dropoff Location" />
      </GoogleMap>
    </>
  );
};

export default Map;
