import { loadStripe } from "@stripe/stripe-js";

import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";

const stripePromise = loadStripe(
  "pk_test_51PCFcJ2KtfRx9uuBsr3dGhjjjFHoGobbtj1wRmyE7hMIZXlxHUiFKvIK71xv4KmCfMEgXbmdtZ9I2dG4aU8JCUHR00YXDwE8FT"
);

const StripePayment = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm />
    </Elements>
  );
};

export default StripePayment;
