import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Image,
  Button,
  Flex,
  VStack,
  Link as ChakraLink,
  HStack,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { fetchJobsAsync } from "../redux/actionCreators/thunks/jobsThunk";
import { useSelector, useDispatch } from "react-redux";

const dbUrl = process.env.REACT_APP_DB_URL;

const ITEMS_PER_PAGE = 12;

const JobsList = ({ search, setSearch }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const jobsList = useSelector((state) => state.jobs.jobs);
  const [jobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const handleButtonClick = (job) => {
    navigate("/postproposal", { state: { job } });
  };


  function formatDate(inputDateString) {
    // Create a new Date object from the input string
    const date = new Date(inputDateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns 0-11, adding 1 to normalize to 1-12
    const day = date.getDate();
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
    return `${year}-${formattedMonth}-${formattedDay}`;
  }
function normalizeDateToUTC(inputDate) {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  
  return `${day}/${month}/${year}`;
}

  function filterJobs(jobsList, searchParams) {
    // Destructure search parameters with defaults to avoid undefined values
    const {
      pickupLocations = [],
      pickupDate = "",
      deliveryDate = "",
      deliveryLocations = [],
      deliveryLocationSubRegions = [],
      pickupLocationSubRegions = [],
    } = searchParams;
    return jobsList.filter((job) => {
      return (
        (pickupLocations.length > 0
          ? pickupLocations.some((location) =>
              job.pickupLocation.includes(location)
            )
          : true) &&
        (pickupLocationSubRegions.length > 0
          ? pickupLocationSubRegions.some((subregion) =>
              job.pickupLocation.includes(subregion)
            )
          : true) &&
        (pickupDate ? formatDate(job.pickupDate) === formatDate(pickupDate) : true) &&
        (deliveryDate
          ? formatDate(job.deliveryDate) === formatDate(deliveryDate)
          : true) &&
        (deliveryLocations.length > 0
          ? deliveryLocations.some((location) =>
              job.deliveryLocation.includes(location)
            )
          : true) &&
        (deliveryLocationSubRegions.length > 0
          ? deliveryLocationSubRegions.some((subregion) =>
              job.deliveryLocation.includes(subregion)
            )
          : true)
      );
    });
  }

  useEffect(() => {
    dispatch(fetchJobsAsync());
    setJobs(jobsList.filter((job) => job.status === "Published").reverse());
  }, [dispatch]);

  useEffect(() => {
    let temp = filterJobs(jobsList, search);
    setJobs(temp.filter((job) => job.status === "Published").reverse());
    setCurrentPage(1); // Reset to first page on new search
  }, [jobsList, search]);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentJobs = jobs.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  const totalPages = Math.ceil(jobs.length / ITEMS_PER_PAGE);

  const handlePageChange = (direction) => {
    setCurrentPage((prevPage) =>
      direction === "next" ? prevPage + 1 : prevPage - 1
    );
  };

  return (
    <Box p="4" w="full">
      <Heading as="h6" fontSize="md">
        Search Results
      </Heading>

      {currentJobs.map((job, index) => (
        <Box
          key={index}
          as="article"
          p="4"
          bg="white"
          boxShadow="md"
          mb="4"
          borderRadius="md"
          my={2}
        >
          <Flex direction={{ base: "column", lg: "row" }}>


            <Box flex={{ base: "1", lg: "3" }} ml={{ base: 0, lg: 4 }}>
              <VStack
                spacing="8"
                mt={{ base: 2, lg: 0 }}
                align={{ base: "center", lg: "start" }}
              >
                <Box textAlign={{ base: "center", lg: "left" }}>
                  <Box onClick={() => handleButtonClick(job)}>
                    <ChakraLink
                      as={Link}
                      className="title"
                      title={job.title}
                      fontWeight="bold"
                    >
                      {job.title}
                    </ChakraLink>
                  </Box>
                  <Text className="rating-wrap">
                    { normalizeDateToUTC(job.postedDate)}
                  </Text>
                  <Text>
                    <Text as="span" mr="2">
                      From:
                    </Text>
                    <Text as="span" fontWeight="bold">
                      {job.pickupLocation.join(", ")}
                    </Text>
                  </Text>
                  <Text>
                    <Text as="span" mr="2">
                      To:
                    </Text>
                    <Text as="span" fontWeight="bold">
                      {job.deliveryLocation.join(", ")}
                    </Text>
                  </Text>
                </Box>

                <Button
                  className="btn-primary"
                  colorScheme="blue"
                  bgColor="#000000"
                  onClick={() => handleButtonClick(job)}
                >
                  Post Proposal
                </Button>
              </VStack>
            </Box>
          </Flex>
        </Box>
      ))}

      <Flex justifyContent="space-between" mt="4">
        <Button
          onClick={() => handlePageChange("prev")}
          isDisabled={currentPage === 1 }
        >
          Previous
        </Button>
        <Text>Page {currentPage} of {totalPages}</Text>
        <Button
          onClick={() => handlePageChange("next")}
          isDisabled={currentPage >= totalPages }
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default JobsList;
