import { ADD_JOB, DELETE_JOB, FETCH_JOBS, MODIFY_JOB } from '../constants';


export const fetchJobs = (jobs) => ({
    type: FETCH_JOBS,
    payload: jobs,
});

export const addJob = (job) => ({
    type: ADD_JOB,
    payload: job,
});

export const modifyJob = (job) => ({
    type: MODIFY_JOB,
    payload: job,
});

export const deleteJob = (id) => ({
    type: DELETE_JOB,
    payload: id,
});

